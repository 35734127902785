import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexAirking = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexAirking {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Air-king/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Air King Watches for Sale'}
      canonical={'/fine-watches/rolex/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Air King Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/filter/model/air-king/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-airking-header.png"
                alt="Pre-Owned Certified Used Rolex Air-King Watches Header"
                aria-label="Used Rolex Air-King Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX AIR-KING: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX AIR-KING WATCHES</h2>
            <p>
              <br />
              <b>Pre-Owned Used Rolex Air-King</b>
              <br />
              <br />
              The Air-King is one of Rolex’s oldest watch collections, originally launched in the
              1940s as a tribute to World War II pilots. However, current production versions are
              vastly different than the original Rolex Air-King watches.
              <br />
              <br />
              Vintage pre-owned Rolex Air-King watches are simpler, with midsize cases,
              straightforward dials, and non-COSC movements. These are sometimes known as Air-King
              Precision watches. In 2007, Rolex released a new generation Air-King collection, which
              maintained similar designs but with chronometer-certified movements. Finally, in 2016
              a completely redesigned Rolex Air-King made its debut with a larger 40mm steel case
              and a black dial.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Rolex Air-King Watches</b>
              <br />
              <br />
              When looking to sell or buy a secondhand Rolex Air-King watch, it’s important to
              understand the various references made throughout the decades. Most pre-owned Air-King
              watches have 34mm steel cases, smooth bezels, and time-only dials. However, Rolex did
              make some variations, such as models with date windows, gold editions, and larger
              sizes. While the Air-King was always one of the brand’s most affordable, the secondary
              market for used Rolex Air-King watches is currently booming with strong resale prices.
              <br />
              <br />
              Popular Pre-Owned Used Rolex Air-King Watch References:
              <br />
              <ul>
                <li>Air-King Precision ref. 5500</li>
                <li>Air-King Precision ref 5501 two-tone</li>
                <li>Air-King Date Precision ref. 5700</li>
                <li>Air-King Precision ref. 5502 and ref. 5506, gold-plated cases</li>
                <li>Air-King Precision ref. 14000 (and ref. 14000M)</li>
                <li>Air-King Precision ref. 14010 (and ref. 14010M), engine-turned steel bezel</li>
                <li>Air-King COSC ref. 114200</li>
                <li>Air-King COSC ref. 114210, engine-turned steel bezel</li>
                <li>Air-King COSC ref. 114234, white gold fluted bezel</li>
                <li>Air-King 40 ref. 116900, 40mm steel case and black dia</li>
              </ul>
              <br />
              <br />
              As an independent Rolex dealer, Gray and Sons benefits from inventory control and
              price flexibility. Our
              <a href="/chat-with-rich">decision-makers </a> are ready to make deals on our
              well-stocked used Rolex watch inventory, which is home to a range of used Air-Kings.
              Any preowned Rolex Air-King for sale on our website is in stock, and ready to ship
              immediately. Alternatively, if you’ve wondered, “where can I sell my used Rolex
              Air-King watch?” visit
              <a href="https://sellusyourjewelry.com/"> www.sellusyourjewelry.com</a> for the best
              cash offer in the market.
              <br />
              <br />
              <b>
                The Best Source for Buying and Selling Pre-Owned Used Rolex Air-King Watches is Gray
                and Sons
              </b>
              <br />
              <br />
              Gray and Sons has had the pleasure of serving thousands of customers over the last 40
              years. And many of those happy clients agree that Gray and Sons is the #1 buyer and
              seller of pre-owned used Rolex Air-King watches. Along with our leading e-commerce
              luxury watch website, <a href="/">www.grayandsons.com</a> Gray and Sons is also a
              brick-and-mortar store, located near the prestigious Bal Harbour Shops in the
              Surfside/Miami area. The Gray and Sons boutique is open six days a week, so come in to
              browse our large selection of secondhand Rolex Air-King watches. Whether you want to
              buy or sell a pre-owned used Rolex Air-King watch, our watch experts are ready to
              assist. Plus, you can also bring your watch in for servicing at our independent Rolex
              Air-King repair facility. Gray and Sons serves the Miami, Miami Beach, Coral Gables,
              Coconut Grove, South Beach, Bal Harbour, Surfside, Sunny Isles, Ventura, Fort
              Lauderdale, Pinecrest, and Brickle areas.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/search/#search=air+king">
                <button>SHOP ROLEX AIR-KING WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX AIR-KING WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexAirking
